import React, { FC, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { withPrefix } from 'gatsby';
import useSiteMetadata from '../../hooks/useSiteMetadata';
import { Sidebar } from '..';
import './Layout.scss';

type Props = {
  children: ReactNode,
  title: string,
  description?: string,
  tags?: string[],
  socialImage?: string,
  isIndex?: boolean
};

const Layout: FC<Props> = ({
  children,
  title,
  description,
  socialImage,
  tags,
  isIndex,
}: Props) => {
  const { author, url } = useSiteMetadata();
  const metaImage = socialImage != null ? socialImage : author.photo;
  const metaImageUrl = url + withPrefix(metaImage);

  return (
    <div className="layout">
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:site_name" content={title} />
        <meta property="og:image" content={metaImageUrl} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={metaImageUrl} />
        {tags && <meta name="keywords" content={tags.join(',')} />}
      </Helmet>
      {isIndex && <Sidebar isIndex />}
      {children}
    </div>
  );
};

Layout.defaultProps = {
  description: '',
  tags: [],
  socialImage: '',
  isIndex: true,
};

export default Layout;
