import React, { FunctionComponent } from 'react';
import './Icon.scss';

type Props = {
  className?: string;
  name: string,
  icon: {
    viewBox?: string,
    path?: string
  }
};

const Icon: FunctionComponent<Props> = ({
  className,
  name,
  icon,
}: Props) => {
  return (
    <svg className={`${className} icon`} viewBox={icon.viewBox}>
      <title>{name}</title>
      <path d={icon.path} />
    </svg>
  );
};

Icon.defaultProps = {
  className: '',
};

export default Icon;
