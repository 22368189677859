import { ICONS } from '../constants';

interface IIcon {
  path: string;
  viewBox: string;
}

class IconHelper {
  public static getIcon(name: string): IIcon {
    switch (name) {
      case 'twitter':
        return ICONS.TWITTER;
      case 'github':
        return ICONS.GITHUB;
      case 'vkontakte':
        return ICONS.VKONTAKTE;
      case 'telegram':
        return ICONS.TELEGRAM;
      case 'email':
        return ICONS.EMAIL;
      case 'rss':
        return ICONS.RSS;
      case 'linkedin':
        return ICONS.LINKEDIN;
      case 'instagram':
        return ICONS.INSTAGRAM;
      case 'line':
        return ICONS.LINE;
      case 'facebook':
        return ICONS.FACEBOOK;
      case 'gitlab':
        return ICONS.GITLAB;
      case 'weibo':
        return ICONS.WEIBO;
      case 'codepen':
        return ICONS.CODEPEN;
      case 'youtube':
        return ICONS.YOUTUBE;
      case 'soundcloud':
        return ICONS.SOUNDCLOUD;
      case 'stackoverflow':
        return ICONS.STACKOVERFLOW;
      case 'home':
        return ICONS.HOME;
      case 'leftArrow':
        return ICONS.LEFT_ARROW;
      default:
        return {} as any;
    }
  }
}

export default IconHelper;
