import React, { FunctionComponent } from 'react';
import './Copyright.scss';

type Props = {
  copyright: string
};

const Copyright: FunctionComponent<Props> = ({
  copyright,
}: Props) => {
  return (
    <div className="copyright">
      {copyright}
    </div>
  );
};

export default Copyright;
