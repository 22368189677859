import React, { FunctionComponent } from 'react';
import Icon from '../../Icon';
import { ContactHelper, IconHelper } from '../../../utils';
import './Contacts.scss';

type Props = {
  contacts: {
    [key: string]: string,
  },
};

const Contacts: FunctionComponent<Props> = ({
  contacts,
}: Props) => {
  return (
    <div className="contacts">
      <ul className="contacts__list">
        {Object.keys(contacts).map((name) => (!contacts[name] ? null : (
          <li className="contacts__list-item" key={name}>
            <a
              className="contacts__list-item-link"
              href={ContactHelper.getContactHref(name, contacts[name])}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Icon name={name} icon={IconHelper.getIcon(name)} />
            </a>
          </li>
        )))}
      </ul>
    </div>
  );
};

export default Contacts;
