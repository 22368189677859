import { useStaticQuery, graphql } from 'gatsby';

const useSiteMetadata = (): any => {
  const { site } = useStaticQuery(
    graphql`
    query SiteMetaData {
      site {
        siteMetadata {
          author {
            name
            bio
            photo
            contacts {
              email
              github
              linkedin
              stackoverflow
            }
          }
          menu{
            label
            path
          }
          url
          title
          subtitle
          copyright
        }
      }
    }
    `,
  );

  return site.siteMetadata;
};

export default useSiteMetadata;
